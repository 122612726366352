<template>
  <v-container fluid>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="5000"
    >
      {{ snackbar }}
    </v-snackbar>
    <v-card flat>
      <v-card-actions>
        <v-btn text class="mb-4" @click="createNewTemplate">New</v-btn>
        <v-btn text class="mb-4" @click="clearTemplate">Clear</v-btn>
        <v-btn text class="mb-4" :disabled="!selectedTemplateId" @click="fetchTemplate(selectedTemplateId)">Reload
        </v-btn>
        <v-spacer/>
        <v-toolbar-items>
          <v-select
            dense
            :loading="loading"
            label="Schedule template"
            :items="templates"
            v-model="selectedTemplateId"
            item-text="name"
            item-value="id"
            @change="$router.push({params: { id: selectedTemplateId }})"
          />
        </v-toolbar-items>
        <v-btn text class="mb-4" :disabled="!selectedTemplateId" @click="saveTemplate(selectedTemplate,days)">Save
        </v-btn>

        <v-spacer/>
        <v-toolbar-items>
          <v-text-field
            :disabled="!selectedTemplateId"
            dense
            label="Number of days"
            v-model="dayCount"
            type="number"
            min="1"
            style="width: 100px"
            @change="updateDayLength(dayCount)"
          />
          <v-select
            :disabled="!selectedTemplateId"
            dense
            single-line
            label="Stream"
            class="ml-3"
            v-model="selectedStreamId"
            :items="streamArray"
            item-text="name"
            item-value="id"
          />
        </v-toolbar-items>
        <v-spacer/>
        <v-btn class="mb-4" :disabled="!selectedTemplateId" text @click="showImportDatePicker=true"
               :loading="importing">Import Meals
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="ratingDialog"
      ref="dialog"
      width="800px"
    >
      <v-card v-if="feedbackMealId">
        <v-card-title v-if="feedbackMeal">
          {{ feedbackMeal.name }}
          <v-spacer/>
          {{ feedbackMeal.sku }} ({{ feedbackMeal.id }})
          <br/>
          {{ dateFormatted(feedbackMeal.date, {formatString: 'ddd, MMM DD, YYYY'}) }}
        </v-card-title>
        <v-card-text>
          <div v-for="(f,index) of feedback.filter(f => f.meal_id==feedbackMealId)" v-bind:key="index">
            <hr/>
            {{ dateFormatted(f.date, {formatString: 'ddd, MMM DD, YYYY'}) }} {{ f.uid }}
            <span class="caption" v-if="f.comments"><br/>{{ f.comments }}</span>
            <v-chip-group>
              <v-chip small v-for="property of ratingProperties"
                      v-bind:key="property"
                      :color="getRatingColor(f[property])">
                {{ property }} {{ f[property] }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showImportDatePicker"
      ref="dialog"
      width="350px"
    >
      <v-card flat>
        <v-card-title>
          Import Meals to Template
        </v-card-title>
        <v-card-text>
          <v-date-picker
            v-model="start"
          />
          <span>Select a starting date to import meals. {{ dayCount }} consecutive days will be imported.</span>
        </v-card-text>
<!--        <v-card-actions>-->
<!--          <v-spacer/>-->
<!--          <v-checkbox label="Skip Empty Slots?" v-model="skipEmptySlots"/>-->
<!--        </v-card-actions>-->
        <v-card-actions>
          <v-btn @click="showImportDatePicker=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn :disabled="!start" @click="showImportDatePicker=false; importHistory()">Import</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showApplyDialog" width="400">
      <v-card>
        <v-card-title>Set meal dates</v-card-title>
        <v-card-text>
          <p>
            Select a date to apply this template.
            Meals will be assigned starting at this date and following the meal pattern in the template.
          </p>
          <v-row class="mt-4" justify="center">
            <v-date-picker
              label="Start Date"
              v-model="applyDateInput"

              @change="setApplyDate(applyDateInput)"
            />
          </v-row>
          <v-select
            label="Days with Meals"
            multiple
            chips
            v-model="daysWithMeals"
            :items="daysOfWeek"
          />
          <v-alert v-if="applyDate" type="info" :value="!!applyDate">
            {{ dateFormatted(applyDate) }}
            to {{ dateFormatted(applyEndDate) }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text class="mb-4 ml-4" @click="setApplyDate(); showApplyDialog=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn text class="mb-4 ml-4" @click="applyTemplate(selectedTemplateId,applyDate)">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card flat v-if="selectedTemplateId && !loading && selectedStream">
      <v-card-title>
        <h2>{{ selectedStream.name }}</h2>
      </v-card-title>
      <v-card-text>
        <p>{{ selectedStream.description }}</p>
        <span class="text-h5" v-if="applyDate">
          {{ dateFormatted(applyDate, {formatString: 'ddd, MMM DD, YYYY'}) }}
          to
          {{ dateFormatted(applyEndDate, {formatString: 'ddd, MMM DD, YYYY'}) }}</span>
        <v-btn color="success" outlined text class="mb-4 ml-4" @click="showApplyDialog=true">Set Meal Dates</v-btn>
        <v-btn v-if="applyDate" color="red" outlined text class="mb-4 ml-4" :loading="saving" @click="publishMeals">
          Publish Meals
        </v-btn>
        <v-spacer/>
        <v-alert outlined text v-if="mealsToClear.length>0" type="warning" :value="mealsToClear.length>0">
          {{ mealsToClear.length }} meals are already scheduled on these dates.

          <span v-if="!saving">
          Do you want to overwrite these meals? <v-btn @click="publishMeals({overwrite: true})">Yes</v-btn>
          </span>
          <span v-else>Please wait while we check all dates...</span>
        </v-alert>

        <v-timeline
          align-top
          dense
        >

          <v-timeline-item
            small
            v-for="(day,index) of days"
            v-bind:key="day.number"
            :color="day.color"
          >
            <!--            <v-lazy-->
            <!--                v-model="day.isActive"-->
            <!--                :options="{ threshold: .1 }"-->
            <!--                min-height="200"-->
            <!--            >-->
            <v-row>
              <v-col cols="12" md="1">
                <p>Day {{ day.number }}</p>
                <p v-if="applyDates[index]">{{ dateFormatted(applyDates[index], {formatString: 'ddd MMM DD'}) }}</p>
              </v-col>
              <v-col>
                <v-row v-for="tod of tods"
                       v-bind:key="tod">
                  <v-col cols="6" sm="2" lg="1">
                    {{ tod }}
                  </v-col>
                  <v-col>
                    <MealAutocomplete
                      style="max-width: 450px"
                      v-if="day.edit[tod]"
                      :label="tod"
                      :meal="day[tod]"
                      v-on:update:meal="updateMeal(day,tod,$event)"
                    />

                    <template v-if="day[tod]">
                      <router-link :to="{name:'MealVersions',query: {sku: `${day[tod].sku}`}}">
                        {{ day[tod].sku }}
                      </router-link>
                      (
                      <template v-if="getSkuSelectedMealId(day[tod])">
                        <router-link :to="{name:'MealTemplate',params: {mealId: `${getSkuSelectedMealId(day[tod])}`}}">
                          <v-icon small color="yellow">mdi-star</v-icon>
                          {{ getSkuSelectedMealId(day[tod]) }}
                        </router-link>
                      </template>
                      <template v-else>
                        <router-link :to="{name:'MealTemplate',params: {mealId: `${day[tod].id}`}}">
                          {{ day[tod].id }}
                        </router-link>
                      </template>
                      )
                      <span @click="$set(day.show,tod,!day.show[tod])">
                            {{ day[tod].name }}
                          </span>
                      <v-alert type="error" v-if="mealCounts[day[tod].name]>1">
                        This meal name appears {{ mealCounts[day[tod].name] }} times this template
                      </v-alert>
                    </template>
                    <v-btn
                      icon
                      x-small
                      @click="$set(day.edit,tod,!day.edit[tod])"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>

                    <template v-if="day[tod]">
                      <v-expansion-panels flat multiple accordion>
                        <v-expansion-panel>
                          <v-expansion-panel-header class="pa-0 ma-0">
                            <v-chip-group>
                              <v-chip small color="primary"
                                      :to="{name:'MealTemplate',params: {mealId: `${day[tod].id}`}}">{{
                                  day[tod].id
                                }}
                              </v-chip>
                              <v-chip small outlined v-if="!getMealRating(day[tod].id)">No feedback</v-chip>
                              <v-chip small v-for="(rating,property) of getMealRating(day[tod].id)"
                                      v-bind:key="`${day[tod].id}.${property}`"
                                      :color="getRatingColor(rating.average)"
                                      @click="showFeedback(day[tod].id)"
                                      @click.native.stop
                              >
                                {{ property }} {{ rating.average }} ({{ rating.count }})
                              </v-chip>
                            </v-chip-group>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <MealTemplateSummary :meal-id="day[tod].id" :dense="true"/>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel
                          v-for="(id,index) of getSkuOtherMealIds(day[tod]).filter(id=>getMealRating(id))"
                          v-bind:key="`${id}.${index}`">
                          <v-expansion-panel-header class="ma-0 pa-0">
                            <v-chip-group>
                              <v-chip small :to="{name:'MealTemplate',params: {mealId: `${id}`}}">{{ id }}</v-chip>
                              <v-chip small v-for="(rating,property) of getMealRating(id)"
                                      v-bind:key="`${id}.${property}`"
                                      :color="getRatingColor(rating.average)"
                                      @click="showFeedback(id)"
                              >
                                {{ property }} {{ rating.average }} ({{ rating.count }})
                              </v-chip>
                            </v-chip-group>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="ma-0 pa-0">
                            <MealTemplateSummary :meal-id="id" :dense="true"/>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--            </v-lazy>-->

          </v-timeline-item>

        </v-timeline>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MealAutocomplete from '@/components/MealAutocomplete';
import {mapActions} from 'vuex';
import moment from 'moment';
import {dateFormatted, deepCopy, getDateString} from '@/store/utils';

import MealTemplateSummary from '@/components/MealTemplateSummary';
import api from '@/api';

const {mapGetters, mapState} = require('vuex');

export default {
  name: "MealScheduleTemplate",
  components: {MealTemplateSummary, MealAutocomplete},
  data() {
    const timeOfDays = 'breakfast,lunch,dinner'.split(',');
    return {
      ratingProperties: 'overall,portion,preparation,presentation,taste'.split(','),
      id: undefined,
      days: [],
      dayCount: 3,
      selectedStreamId: null,
      start: null,
      meals: [],
      loading: false,
      templates: [],
      templateName: null,
      defaultDay: {
        edit: {},
        show: {}
      },
      tods: timeOfDays,
      selectedTemplateId: null,
      showApplyDialog: null,
      daysWithMeals: 'mon,tue,wed,thu,fri,sat'.split(','),
      daysOfWeek: 'sun,mon,tue,wed,thu,fri,sat'.split(','),
      importing: null,
      showImportDatePicker: null,
      today: moment().format(moment.HTML5_FMT.DATE),
      applyDateInput: null,
      selectedStream: null,
      mealsToClear: [],
      saving: false,
      snackbar: null,
      showSnackbar: null,
      ratings: {},
      ratingDialog: null,
      feedback: [],
      feedbackMealId: null,
      feedbackMeal: null,
      skipEmptySlots: null
    }
  },
  async mounted() {
    this.loading = true;
    this.updateDayLength(this.dayCount);
    await Promise.all([this.fetchStreams(), this.fetchDiets(), this.fetchTemplates()]);
    // await this.updateStream();
    this.selectedStreamId = this.streamArray[0].id;

    this.id = this.$route.params.id;
    return this.fetchTemplate(this.id);
  },
  watch: {
    snackbar(val) {
      this.showSnackbar = val;
    },
    selectedStreamId: 'updateStream',
    //dayCount: 'updateDayLength',
    '$route.params.id'(id) {
      if (this.$route.name !== 'MealScheduleTemplate') {
        console.log('different page, ignore');
      } else if (!id) {
        console.log('id not defined');
      } else if (this.id !== id) {
        console.log('id changed ', [this.id, id]);
        return this.fetchTemplate(id);
      } else {
        console.log('already on ', id);
      }
    }
  },
  methods: {
    ...mapActions(['fetchDiets', 'fetchStreams', 'fetchMeals', 'fetchMeal',]),
    moment,
    getDateString,
    dateFormatted,
    getSkuOtherMealIds(meal) {
      return meal.SKU
        ? meal.SKU.meals.map(s => s.id).filter(id => meal.id !== id).sort().reverse()
        : [];
    },
    fetchTemplate(id) {
      console.log('fetch template', id);
      this.loading = true;
      this.days.splice(0);
      if (!id) {
        // reset state
        this.selectedTemplateId = undefined;
        this.selectedStreamId = this.streamArray[0].id;
        this.updateDayLength(this.dayCount);
        this.id = undefined;
        this.loading = false;
      } else {
        this.id = id;
        return api.get(`v2/schedule_template/${id}`)
          .then(({data}) => {
            console.log('template', data);
            return this.updateSlots(data);
          })
          .catch(e => {
            console.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async fetchFeedback(mealIds) {
      console.log('fetching feedback for', mealIds);
      if (!mealIds || mealIds.length === 0) {
        console.log('no id to fetch');
        return;
      }
      const ratingProperties = this.ratingProperties;
      const ratings = {};

      function addRating(mealId, property, value) {
        if (value) {
          const mealRating = ratings[mealId] || (ratings[mealId] = {});
          const mealPropertyRating = mealRating[property] || (mealRating[property] = {total: 0, count: 0});
          mealPropertyRating.total += value;
          mealPropertyRating.count++;
          mealPropertyRating.average = (mealPropertyRating.total / mealPropertyRating.count).toFixed(1);
        }
      }

      await api.post('feedback/get-by-id', {mealIds})
        .then(response => {
          const feedbackRecords = response.data;
          this.feedback = feedbackRecords;
          for (const f of feedbackRecords) {
            ratingProperties.forEach(property => addRating(f.meal_id, property, f[property]))
          }
          Object.assign(this.ratings, ratings);
        });
    },
    async updateSlots(template) {
      console.log('update slots', template);
      this.dayCount = template.slots.length > 0 ? Math.max(...template.slots.map(s => s.day)) : 1;
      this.updateDayLength(this.dayCount);
      this.selectedTemplateId = template.id;
      this.selectedStreamId = template.stream;
      const mealIds = template.slots.map(s => s.meal_id);
      await Promise.all([
        // this.fetchFeedback(mealIds),
        ...template.slots
          .map(slot => api
            //.get(`v2/public/meal/${slot.meal_id}`)
            .get(`v2/meal/${slot.meal_id}`)
            .then(({data}) => slot.meal = data)
          )]);
      // const mealIdsSet = new Set(mealIds);
      const skuMealIds = new Set(mealIds);
      template.slots
        .forEach(slot => {
          if (slot.meal && slot.meal.SKU) {
            slot.meal.SKU.meals
              .forEach(m => skuMealIds.add(m.id))
            // console.log('adding ', slot.meal.SKU.meals);
          } else {
            console.warn('no meal or sku', slot)
          }
        })
      await this.fetchFeedback([...skuMealIds]);

      template.slots.map(slot => this.updateSlot(slot));
    },
    updateSlot(slot) {
      const todToString = {
        1: 'breakfast',
        2: 'lunch',
        3: 'dinner'
      }
      const day = this.days[slot.day - 1];
      const todString = todToString[slot.tod];
      this.$set(day, todString, slot.meal);

      // .catch(e => {
      //   console.error('failed to load ' + slot.meal_id, e)
      //   day[todString].name = 'Failed to load'
      // });
    },
    async fetchTemplates() {
      return api.get('v2/schedule_template')
        .then(({data}) => {
          console.log('templates', data.sort((a, b) => a.name.localeCompare(b.name)));
          this.templates = data.sort((a, b) => a.name.localeCompare(b.name));
        })
        .catch(e => {
          console.error(e);
        })
    },
    async updateStream() {
      const stream = this.streamArray.find(({id}) => id === this.selectedStreamId);
      this.selectedStream = stream;
      // let mealOffset = 0;
      this.days.forEach((d, i) => {
        // console.log('update day ' + i, this.meals[i]);
        this.$set(this.days, i, deepCopy({
          ...d,
          stream,
          color: this.selectedDiet ? this.getDietColor(this.selectedDiet.id) : 'white',
          ...this.defaultDay
        }));
      });
    },
    updateDayLength(n) {
      const stream = this.selectedStream;
      const color = this.selectedDiet ? this.getDietColor(this.selectedDiet.id) : '';
      // console.log('updateDayLength',n);
      while (this.days.length < n) {
        const number = this.days.length + 1;
        // console.log('adding day',number);
        this.days
          .push(
            deepCopy({
              number: number,
              stream,
              color,
              ...this.defaultDay
            }));
      }
      this.days.splice(n, this.days.length);
      // console.log('this.days', this.days);
    },
    async importHistory() {
      this.importing = true;
      if (!this.selectedDiet) {
        alert('cannot import history unless a diet is selected');
      }

      const start = moment(this.start);
      console.log('importing meals starting at date', start.format(moment.HTML5_FMT.DATE));
      for (const day of this.days) {
        let meals;
        let retry = 0;
        let date;
        do {
          date = start.format(moment.HTML5_FMT.DATE);
          const url = `meals/${date}`;
          console.log('importing meals from ', url);
          meals = ((await api.get(url)).data)
            .filter(m => m.diet === this.selectedDiet.id && [1, 2, 3].includes(m.tod));
          start.add(1, 'day');
          if (++retry === 100) {
            alert(`too many days with no meals, giving up ${date}`);
            this.importing = false;
            return;
          }
        } while (meals.length === 0 && !this.skipEmptySlots);

        this.snackbar = `importing meals for day ${day.number} from ${date}`;
        this.$set(day, 'breakfast', meals.find(m => m.tod === 1));
        this.$set(day, 'lunch', meals.find(m => m.tod === 2));
        this.$set(day, 'dinner', meals.find(m => m.tod === 3));
      }
      this.snackbar = 'Importing of meals complete';

      this.importing = false;
    },

    updateMeal(day, tod, meal) {
      console.log('update meal', meal);
      this.$set(day.edit, tod, false);
      this.$set(day, tod, meal);
      if (meal && !this.ratings[meal.id]) {
        return this.fetchFeedback([meal.id]);
      }
    },
    saveTemplate(template, days) {
      let id;
      let name;
      if (template.name) {
        id = template.id;
        name = template.name;
      } else {
        name = template;
      }
      const slots = days.flatMap(day => [
        {
          day: day.number,
          tod: 1,
          meal_id: day.breakfast && day.breakfast.id
        },
        {
          day: day.number,
          tod: 2,
          meal_id: day.lunch && day.lunch.id
        },
        {
          day: day.number,
          tod: 3,
          meal_id: day.dinner && day.dinner.id
        }
      ]);
      const scheduleTemplate = {
        name,
        id,
        stream: this.selectedStreamId,
        slots
      };
      console.log('save payload', scheduleTemplate);
      api.post('v2/schedule_template', scheduleTemplate)
        .then(async ({data}) => {
          console.log('saved', data);
          if (data.id !== this.id) {
            await this.fetchTemplates();
            return this.$router.push({params: {id: data.id}});
          }
        }).catch(e => console.error(e));
    },
    applyTemplate(template, date) {
      console.log('applying template', {date, template});
      this.showApplyDialog = false;
    },
    async publishMeals({overwrite = false}) {
      try {
        if (!confirm('Are you sure you want to write these meals to the upcoming schedule?  No going back.')) {
          return;
        }
        this.mealsToClear = [];
        this.saving = true;
        for (const m of this.applyDates) {
          const date = getDateString(m);
          // console.log('day', m, date, day);
          this.snackbar = `checking for existing meals on ${date}`;
          let mealsOnDate = await api.get(`meals/${date}`).then(({data}) => data);
          mealsOnDate = mealsOnDate.filter(m => m.diet === this.selectedDiet.id)
          // console.log('mealsOnDate filtered', mealsOnDate);
          if (overwrite) {
            await Promise.all(mealsOnDate.map(m => api.delete(`meals/delete/${m.id}`)));
          } else {
            this.mealsToClear.push(...mealsOnDate);
            // console.log('added meals to mealstoclear', mealsOnDate);
          }
        }

        // console.log('mealsToClear', this.mealsToClear);
        if (this.mealsToClear.length === 0) {

          const days = this.days.map((day, index) => {
            const m = this.applyDates[index];
            const date = getDateString(m);
            return {...day, date};
          });

          for (const day of days) {
            this.snackbar = `copying meals on ${day.date}`;
            await this.copyMeal(day.breakfast, day.date, 'breakfast');
            await this.copyMeal(day.lunch, day.date, 'lunch');
            await this.copyMeal(day.dinner, day.date, 'dinner');
          }
        }
      } catch
        (e) {
        console.error('failed', e);
      }

      this.snackbar = 'finished';
      this.saving = false;
    },
    async copyMeal(meal, date, tod) {
      if (!meal) {
        console.warn('no meal in this slot', {date, tod});
        return;
      }

      const mealId = this.getSkuSelectedMealId(meal) || meal.id;
      tod = this.getTimeOfDayNumber(tod);

      console.log('copying meal', {mealId, tod, date});

      const diet = this.selectedDiet;
      // console.log('diet', diet);
      // return
      return api.post(`/v2/meal/copy/${mealId}`)
        .then(result => {
            const update = {tod, date};
            const {data: copy} = result;
            // console.log('made copy', copy);
            if (copy.diet !== diet.id) {
              console.log('copy diet did not match, forcing change', copy.diet, diet);
              update.diet = diet.id;
            }
            return api.put(`v2/meal/${copy.id}`, {meal: update});
          }
        )
        .then(async r => {
          // console.log('result', r);
          const newMeal = r.data;
          console.log('finished copy of meal', newMeal);
          // await api.post('meals/version', {main: mealId, submeal: newMeal.id});
          // await Promise.all(
          //     ingredients
          //         .map(i => {
          //           delete i.mealid;
          //           delete i.id;
          //           return i;
          //         })
          //         .map(i =>
          //             api.post(`v2/meal/${newMeal.id}/ingredients`, i)
          //                 .catch(e => {
          //                   console.error('failed to add ingredient: ' + e, i);
          //                 })
          //         ));
        })
        .catch(e =>
          console.error('error' + e, {mealId, e})
        );
    }
    ,
    setApplyDate(applyDateInput) {

      const date = dateFormatted(applyDateInput, {formatString: moment.HTML5_FMT.DATE});
      console.log('setApplyDate', date);
      if (date !== this.applyDate) {
        this.$router.push({query: {date}});
      } else {
        console.log('setApplyDate ignored, already set', date);
      }
    }
    ,
    createNewTemplate() {
      const name = prompt('Enter a name for the new template');
      if (name) {
        this.saveTemplate(name, []);
      }
    }
    ,
    clearTemplate() {
      this.days = [];
      this.updateDayLength(this.dayCount);
    },
    getMealRating(mealId) {
      return this.ratings[mealId];
    },
    getRatingColor(value) {
      const colorRatings = [
        'red',
        'orange',
        'yellow',
        'light-green',
        'green',
      ]
      value = parseInt(value);
      return colorRatings[value - 1];
    },
    showFeedback(id) {
      this.ratingDialog = true;
      this.feedbackMealId = id;
      return this.fetchMeal(id)
        .then(m => this.feedbackMeal = m);
    },
    getSkuSelectedMealId(m) {
      return m.SKU && m.SKU.selected_meal_id
    }
  },

  computed: {
    ...mapState(['streams', 'user']),
    ...mapGetters(['getDietColor', 'getStreamDiet', 'getTimeOfDayNumber']),
    applyDate() {
      return this.$route.query.date
    },
    streamArray() {
      let streams = Object.values(this.streams);
      if (this.user.streams && this.user.streams.length > 0) {
        streams = streams.filter(s => this.user.streams.includes(s.id));
      }
      return streams;
    },
    selectedDiet() {
      return (this.selectedStreamId) ? this.getStreamDiet(this.selectedStreamId) : undefined;
    },
    applyDates() {
      const dates = [];
      if (this.daysWithMeals.length === 0) {
        alert('must select at least one day per week');
        return;
      }
      if (!this.applyDate) {
        return dates;
      }
      const date = moment(this.applyDate).utc().startOf('day');
      let days = this.dayCount;
      console.log('calculating apply dates', this.applyDate);
      if (days > 0) {
        while (days-- > 0) {
          while (this.daysWithMeals.length > 0 &&
          !this.daysWithMeals.includes(date.format('ddd').toLowerCase())) {
            // skip
            date.add(1, 'day');
          }
          dates.push(getDateString(moment(date)));
          // go to next date
          date.add(1, 'day');
        }
        //return;
      }
      return dates;
    },
    applyEndDate() {
      const dates = this.applyDates;
      if (dates.length > 0) {
        return dates[dates.length - 1];
      } else
        return undefined;
    },
    selectedTemplate() {
      return this.templates.find(t => t.id === this.selectedTemplateId);
    },
    mealCounts() {
      const counts = {};

      function increment(daySlot) {
        if (daySlot) {
          const id = daySlot.name;
          counts[id] = counts[id] || 0;
          counts[id]++;
        }
      }

      this.days.forEach(day => {
        // console.log('day', day);
        increment(day.breakfast);
        increment(day.lunch);
        increment(day.dinner);
      })
      // console.log(counts);
      return counts;
    }
  }
}
</script>

<style scoped>

</style>
