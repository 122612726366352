<template>
  <v-container fluid class="pt-0 mt-0">
    <v-alert v-if="loading">Loading</v-alert>
    <v-card v-if="!loading" class="">
      <v-card-text>
        <v-row>
          <v-col>
            {{ getDietName(meal.diet) }} ({{ getTimeOfDay(meal.tod) }}) - {{ getCookedAmountTotal(meal.id).toFixed() }}g
          </v-col>
          <v-col align="right">
            {{ dateFormatted }}
          </v-col>
        </v-row>
        <v-row class="py-0 my-0">
          <v-col class="py-0 my-0">
            {{ meal.name }}
          </v-col>
        </v-row>

        <v-simple-table
            class="table-summary"
            dense
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="pl-1" :class="amountHeaderClass" :width="ingredientWidth">
              </th>
              <th :width="amountWidth" :class="amountHeaderClass">
                {{ date && formatSummary.small }} Small
              </th>
              <th :width="amountWidth" :class="amountHeaderClass">
                {{ date && formatSummary.medium }} Medium
              </th>
              <th :width="amountWidth" :class="amountHeaderClass">
                {{ date && formatSummary.large }} Large
              </th>
              <th :width="amountWidth" :class="amountHeaderClass" class="d-print-none">
                Monosize
              </th>
            </tr>
            </thead>
            <tbody>
            <PlatingRow
                font-size=""
                :read-only="true"
                class="platingTable"
                v-for="(c,componentName) of ingredientsByComponent"
                v-bind:key="c.id"
                :title="componentName"
                :subtitle="Object.entries(formatSummary).join(' ')"
                :component="components[c.id] || {}"
                :ingredients="c.ingredients"
                :summary="summary"
                :dense="dense"
            />
            </tbody>
          </template>
        </v-simple-table>
        <v-dialog
            fullscreen
            v-model="showComponentDialog"
        >
          <MealToComponent
              :ingredientsByComponent="ingredientsByComponent"
              v-on:close="showComponentDialog=false"
              v-on:closeAndSave="createComponentAndMoveIngredients($event)"
          />
        </v-dialog>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import MealToComponent from '@/components/MealToComponent';
import PlatingRow from '@/components/PlatingRow';
import moment from 'moment';

export default {
  components: {
    MealToComponent,
    PlatingRow
  },
  props: {
    readOnly: Boolean,
    mealId: [String, Number],
    dense: Boolean
  },
  data() {
    return {
      loading: true,
      showComponentDialog: false,
      amountWidth: this.dense ? '20px;' : '60px',
      ingredientWidth: '*', //this.dense ? '' : '*',
      amountClass: this.dense ? 'px-1 py-0 my-0 text-right' : '',
      amountHeaderClass: '',
      date: null
    }
  },
  watch: {
    // mealId() {
    //   this.fetchData();
    // }
  },
  computed: {
    ...mapGetters([
      'getSummaryForDiet',
      'components',
      'getComponentName',
      'getDietName',
      'getTimeOfDay',
      'getCookedAmountTotal'
    ]),
    activeIngredients() {
      return this.ingredients.filter(i => !i.toDelete);
    },
    meal() {
      return this.$store.getters.getMeal(this.mealId);
    },
    summary() {
      if (this.date) {
        return this.getSummaryForDiet(this.date, this.meal.diet, this.meal.tod)
      } else {
        return {small: '', medium: '', large: '', total: 0};
      }
    },
    ingredientsByComponent() {
      const result = {};
      // console.log('this.com', this.components);
      this.ingredients.forEach(i => {
        let componentName = this.getComponentName(i.component_id);
        if (result[componentName] && result[componentName].id !== i.component_id) {
          componentName = `${componentName}-${i.component_id}`;
        }
        result[componentName] = result[componentName] || {
          id: i.component_id,
          ingredients: []
        };
        result[componentName].ingredients.push(i);
      });
      return result;
    },
    ingredients() {
      const ingredients = this.$store.getters.getMealIngredients(this.mealId);
      ingredients.slice().sort((a, b) => {
        const componentNameA = a.component_name || 'ZZZ';
        const componentNameB = b.component_name || 'ZZZ'
        if (componentNameA !== componentNameB) {
          return componentNameA.localeCompare(componentNameB);
        }
        return (a.fooddescriptionsimple || a.fooddescription || '').localeCompare(b.fooddescriptionsimple || b.fooddescription || '');
      });
      // console.log('ingredients', ingredients.map(i => [i.fooddescriptionsimple, i.component_name].join('-')));
      return ingredients;
    },
    formatSummary() {
      const s = this.summary;
      const small = s.small || 0;
      const medium = s.medium || 0;
      const large = s.large || 0;
      const total = s.total || 0;
      const count = small + medium + large;
      return {small, medium, large, total, count};
    },
    dateFormatted() {
      return moment(this.date).format('dddd MMM DD yyyy');
    },
  },
  mounted() {
    return this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchMeal',
      'fetchMealIngredients',
      'fetchOrders',
      'fetchDiets',
      'createComponent',
      'updateIngredient',
      'updateComponent',
      'replaceIngredientFood'
    ]),
    fetchData() {
      console.log('creating detail for ', this.mealId);
      this.loading = true;
      return Promise.all([
        this.fetchMeal(this.mealId)
            .then(meal => {
              this.date = meal.date;
              return this.fetchOrders(this.date);
            }),
        this.fetchMealIngredients({id:this.mealId}),
        this.fetchDiets(),
      ])
          .then(() => this.loading = false);

    },
    goBack() {
      this.$router.go(-1);
    },
    showTags(ingredients) {
      const tags = {};
      ingredients
          .forEach(i => {
            if (i.tags) {
              i.tags.forEach(tag => {
                tags[tag] = tags[tag] || 0;
                tags[tag]++;
              });
            }
          });
      return tags;
    },
    isMonosized(id) {
      return this.components[id] && this.components[id].monosized;
    },
    addIngredient(component_id) {
      const componentName = this.getComponentName(component_id);
      this.ingredients.push({
        component_id,
        component_name: componentName,
        fooddescriptionsimple: '',
        editing: true,
        mealid: this.meal.id,
        portion_multiplier: 0,
        portion_basis: 1455
      });
      console.log('this.ingredients', this.ingredients);
    },
    async createComponentAndMoveIngredients({component_name, monosized, ingredientsToMove}) {
      console.log('creating component', {component_name, monosized, ingredientsToMove})
      let emptyComponent = true;
      let {id} = await this.createComponent({name: component_name, monosized});
      this.ingredients.forEach(i => {
        if (ingredientsToMove[i.foodid]) {
          emptyComponent = false;

          this.replaceIngredientFood({
            oldIngredient: i,
            newIngredient: {
              ...i,
              component_id: id
            }
          });

          // this.$set(i, 'component_id', id);
          // this.$set(i, 'component_name', component_name);
          // console.log('adding to component', [component_name, i.foodid, i.fooddescriptionsimple]);
        }
      });
      // this.components[component_id]
      console.log('this.ingredients', this.ingredients.filter(i => ingredientsToMove[i.foodid]));
      if (emptyComponent) {
        this.addIngredient(id);
      }
      this.showComponentDialog = false;
    },
  }
}
</script>

<style>

.table-summary .v-text-field__slot input {
  text-align: right;
}

</style>
